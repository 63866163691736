import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { basketActions } from "store/basket";

import Page from "components/ui/Page";
import Header from "components/ui/Header";
import Basket from "components/ui/Basket";
import Product from "./Product";
import Footer from "components/ui/Footer";
import Alert from "components/ui/Alert";
import Spinner from "components/ui/Spinner";
import ProductSkeleton from "./Skeleton";

import useAppInit from "hooks/useAppInit";
import useProducts from "hooks/useProducts";

import { UserFlowType } from "types";

import "./Menu.scss";

export default function Menu() {
  const { t } = useTranslation();

  const { userFlowType } = useCustomSelector((state) => state.user);
  const { basketItems } = useCustomSelector((state) => state.basket);
  const { isLoading: menuIsLoading } = useCustomSelector((state) => state.menu);

  const { isLoading: appIsLoading } = useAppInit();
  const { products, isSingleProductSetup } = useProducts();

  const dispatch = useCustomDispatch();
  const navigate = useNavigate();

  // If there is only 1 product, automatically place the order with that product
  useEffect(() => {
    if (isSingleProductSetup) {
      if (basketItems.length === 0) {
        let product = products[0];
        dispatch(basketActions.addBasketItem(product));
      }
      navigate(userFlowType === UserFlowType.GUEST ? "/payment" : "/order");
    }
  }, [products, isSingleProductSetup, userFlowType, basketItems.length, dispatch, navigate]);

  if (appIsLoading) {
    return <Spinner />;
  }

  return (
    <Page className="menu-page">
      <Header />
      <Basket />
      <div className="page-content">
        {products.length > 0 ? (
          products.map((product) => <Product key={product.productId} product={product} />)
        ) : menuIsLoading ? (
          <>
            <ProductSkeleton />
            <ProductSkeleton />
          </>
        ) : (
          <p>{t("common:EmptyMenu")}</p>
        )}
      </div>
      <Footer />
      <Alert withCountdown />
    </Page>
  );
}
