import { useEffect } from "react";
import { useCustomDispatch } from "store/useStore";
import { userActions } from "store/user";
import { basketActions } from "store/basket";
import { menuActions } from "store/menu";

import Page from "components/ui/Page";
import Header from "components/ui/Header";
import ActionCards from "./ActionCards";
import HomeBackground from "./Background";
import Alert from "components/ui/Alert";
import Spinner from "components/ui/Spinner";

import useAppInit from "hooks/useAppInit";

import { ReactComponent as CutleryIcon } from "assets/img/icons/cutlery.svg";

import "./Home.scss";

export default function Home() {
  const { isLoading } = useAppInit();

  const dispatch = useCustomDispatch();

  // Clearing the session when returning to home screen
  useEffect(() => {
    dispatch(basketActions.clearBasketItems());
    dispatch(userActions.setCardNumber(null));
    dispatch(userActions.setExternalPaymentId(null));
    dispatch(userActions.setUserType(null));
    dispatch(menuActions.setProducts([]));
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <HomeBackground />
      <Page className="home-page">
        <Header className="home-header" />
        <div className="page-content">
          <ActionCards />
        </div>
        <p className="home-footer">
          Powered by Crunchorder <CutleryIcon /> Loomis Pay
        </p>
        <Alert />
      </Page>
    </>
  );
}
