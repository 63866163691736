import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getBasketItemsFromStorage, storeBasketItems } from "utils/helpers/storageMapper";

import { BasketStore, BasketItem, Product } from "types";

const initialState: BasketStore = {
  basketItems: [],
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    loadBasketItems: (state, action: PayloadAction<Product[]>) => {
      state.basketItems = getBasketItemsFromStorage(action.payload);
    },
    addBasketItem: (state, action: PayloadAction<Product>) => {
      let product = action.payload;
      let basketItem = state.basketItems.find((item) => item.productId === product.productId) || { ...product, amount: 0 };
      basketItem.amount++;

      state.basketItems = [...state.basketItems, basketItem];
      storeBasketItems(state.basketItems);
    },
    updateBasketItemAmount: (state, action: PayloadAction<{ item: BasketItem; amount: number }>) => {
      const { item, amount } = action.payload;
      let itemIndex = state.basketItems.findIndex(({ productId }) => productId === item.productId);
      if (itemIndex === -1) {
        return;
      }

      if (amount === 0) {
        state.basketItems.splice(itemIndex, 1);
      } else {
        state.basketItems[itemIndex].amount = amount;
      }
      storeBasketItems(state.basketItems);
    },
    removeBasketItem: (state, action: PayloadAction<BasketItem>) => {
      let removeIndex = state.basketItems.findIndex((item) => item.productId === action.payload.productId);
      state.basketItems.splice(removeIndex, 1);
      storeBasketItems(state.basketItems);
    },
    clearBasketItems: (state) => {
      state.basketItems = [];
      storeBasketItems(state.basketItems);
    },
  },
});

export const slice = basketSlice.reducer;
export const actions = basketSlice.actions;
