import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { userActions } from "store/user";

import Card from "components/ui/Card";
import Button from "components/ui/Button";

import useRfidReader from "hooks/useRfidReader";

import { UserFlowType } from "types";

import "./ActionCards.scss";

export default function ActionCards() {
  const { t } = useTranslation();

  const { settings } = useCustomSelector((state) => state.app);

  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  const useEmployeeFlow = useMemo(() => !!settings?.accountCardEnabled, [settings]);
  const useGuestFlow = useMemo(() => !!settings?.paymentTerminalEnabled, [settings]);

  const { uid } = useRfidReader(useEmployeeFlow);

  useEffect(() => {
    if (!!uid) {
      dispatch(userActions.setCardNumber(uid));
      dispatch(userActions.setUserType(UserFlowType.EMPLOYEE));
      navigate("/menu");
    }
  }, [uid, dispatch, navigate]);

  function handleGuestClick() {
    dispatch(userActions.setUserType(UserFlowType.GUEST));
    navigate("/menu");
  }

  if (useEmployeeFlow && !useGuestFlow) {
    return (
      <Card className="action-card primary-action-card">
        <p>{t("home:EmployeeCardAction")}</p>
      </Card>
    );
  }

  if (useGuestFlow && !useEmployeeFlow) {
    return (
      <Card className="action-card primary-action-card guest-action-card">
        <p className="title">{t("home:GuestAction.Welcome")}</p>
        <Button size="lg">{t("home:GuestAction.Button")}</Button>
      </Card>
    );
  }

  return (
    <>
      <Card className="action-card primary-action-card">
        <p>{t("home:EmployeeCardAction")}</p>
      </Card>
      <Card className="action-card secondary-action-card" onClick={handleGuestClick}>
        <p className="preface">{t("home:GuestAction.Preface")}</p>
        <p className="title">{t("home:GuestAction.Title")}</p>
        <Button size="lg">{t("home:GuestAction.Button")}</Button>
      </Card>
    </>
  );
}
