import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomSelector } from "store/useStore";

import Page from "components/ui/Page";
import Button from "components/ui/Button";

import HALService from "services/hal";

import useBasketValues from "hooks/useBasketValues";

import { getOrderReceiptHtml } from "utils/helpers/misc";
import { customInsightsEvent } from "utils/helpers/logs";

import { ReactComponent as SuccessIcon } from "assets/img/icons/checkmark.svg";

import "./Order.scss";

enum OrderState {
  "PENDING" = 0,
  "SUCCESS" = 1,
  "ERROR" = 2,
}

export default function Order() {
  const { t } = useTranslation();

  const { settings } = useCustomSelector((state) => state.app);
  const { externalPaymentId } = useCustomSelector((state) => state.user);

  const navigate = useNavigate();

  const { displayTotal, basketItems } = useBasketValues();

  const [progressAnimationContent, setProgressAnimationContent] = useState("...");
  const [orderState, setOrderState] = useState<OrderState>(OrderState.PENDING);

  const finishOrderFlow = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSuccesfulOrder = useCallback(() => {
    setOrderState(OrderState.SUCCESS);
    HALService.print(getOrderReceiptHtml({ externalPaymentId, displayTotal, products: basketItems }));
  }, [displayTotal, basketItems]);

  useEffect(() => {
    function updateAnimationContent() {
      setProgressAnimationContent((previous) => {
        if (previous.length === 3) {
          return ".";
        }
        return previous + ".";
      });
    }

    let interval = setInterval(updateAnimationContent, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // TEMP
  useEffect(() => {
    let timeout = setTimeout(handleSuccesfulOrder, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [handleSuccesfulOrder]);

  useEffect(() => {
    function handleAutomaticRedirect() {
      finishOrderFlow();
    }

    let timeout: any;
    if (orderState === OrderState.SUCCESS) {
      timeout = setTimeout(handleAutomaticRedirect, 10000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [orderState, finishOrderFlow]);

  useEffect(() => {
    if (orderState === OrderState.ERROR) {
      customInsightsEvent("FailedOrder", { unitId: settings?.unitId || "Ukendt", transactionId: "test" });
      throw new Error("Order failed");
    }
  }, [orderState, settings]);

  if (orderState === OrderState.SUCCESS) {
    return (
      <Page className="order-success-page">
        <div className="order-success-content">
          <p>
            <SuccessIcon /> {t("order:SuccessTitle")}
          </p>
          <Button className="finish-button" onClick={finishOrderFlow}>
            {t("order:Finish")}
          </Button>
        </div>
      </Page>
    );
  }

  return (
    <Page className="order-progress-page">
      <div className="order-progress-content">
        <p>
          {t("order:ProgressTitle")}
          <span className="progress-animation">{progressAnimationContent}</span>
        </p>
      </div>
    </Page>
  );
}
