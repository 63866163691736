import "./Counter.scss";

export default function Counter({ count, handleIncrease, handleDecrease }: { count: number; handleIncrease: (updatedCount: number) => void; handleDecrease: (updatedCount: number) => void }) {
  return (
    <div className="counter">
      <button onClick={() => handleDecrease(count - 1)}>-</button>
      <p>{count}</p>
      <button onClick={() => handleIncrease(count + 1)}>+</button>
    </div>
  );
}
