import { useEffect, useMemo } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { menuActions } from "store/menu";

import BackendService from "services/backend";

const isAfterFromTime = ({ fromTime, currentHour, currentMinute }: { fromTime: Date; currentHour: number; currentMinute: number }) => {
  if (fromTime.getHours() > currentHour) {
    // Later hour than now
    return false;
  }
  if (fromTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return fromTime.getMinutes() <= currentMinute;
  }
  // Hour is before current one
  return true;
};

const isBeforeToTime = ({ toTime, currentHour, currentMinute }: { toTime: Date; currentHour: number; currentMinute: number }) => {
  if (toTime.getHours() <= currentHour) {
    // Before current hour
    return false;
  }
  if (toTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return toTime.getMinutes() > currentMinute;
  }
  // Hour is after current one
  return true;
};

export default function useProducts() {
  const { products } = useCustomSelector((state) => state.menu);
  const { cardNumber } = useCustomSelector((state) => state.user);

  const dispatch = useCustomDispatch();

  const availableProducts = useMemo(() => {
    let available = products;
    const now = new Date();

    // Weekday filter
    const today = now.getDay();
    available = available.filter(({ weekdays = [] }) => weekdays.includes(today));

    // Time of day filter
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    available = available.filter(({ activeFrom, activeTo }) => {
      if (!activeFrom || !activeTo) {
        return true;
      }
      let fromTimestamp = new Date(activeFrom);
      let toTimestamp = new Date(activeTo);
      return isAfterFromTime({ fromTime: fromTimestamp, currentHour, currentMinute }) && isBeforeToTime({ toTime: toTimestamp, currentHour, currentMinute });
    });

    return available;
  }, [products]);

  useEffect(() => {
    async function loadMenu() {
      dispatch(menuActions.setIsLoading(true));
      let menuResponse = await BackendService.getMenu(cardNumber);
      dispatch(menuActions.setIsLoading(false));
      if (menuResponse.isSuccess()) {
        let fetchedProducts = menuResponse.data.products;
        if (fetchedProducts[0]) {
          fetchedProducts[0].imageUrl = "https://billedarkiv.blob.core.windows.net/images/996326f3-3cc5-4cb7-af6a-5f37cf4dd806";
        }
        dispatch(menuActions.setProducts(fetchedProducts));
      }
    }

    if (products.length === 0) {
      loadMenu();
    }
  }, [cardNumber, products.length, dispatch]);

  return { products: availableProducts, isSingleProductSetup: false }; // availableProducts.length === 1
}
