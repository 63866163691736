import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch } from "store/useStore";
import { appActions } from "store/app";

import Input from "./Input";
import Button from "components/ui/Button";

import BackendService from "services/backend";
import HALService from "services/hal";

import { AdminAppSettings, AdminCustomSettings } from "types";

import "./Credentials.scss";

export default function Credentials() {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const navigate = useNavigate();

  const [tokenInput, setTokenInput] = useState("");
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [hasError, setHasError] = useState(false);

  async function handleTokenButtonClick() {
    setIsLoadingSettings(true);
    setIsInvalidToken(false);
    setHasError(false);

    const unitSettingsResponse = await BackendService.getUnitSetup(tokenInput);
    if (!unitSettingsResponse.isSuccess()) {
      setIsLoadingSettings(false);
      setIsInvalidToken(true);
      return;
    }

    const unitSetting = unitSettingsResponse.data.unitSetting;

    // Set values in AdminAppSettings
    let existingAppSettings = await HALService.getAppSettings();
    if (!existingAppSettings.isSuccess()) {
      setHasError(true); // No HAL
      setIsLoadingSettings(false);
      return;
    }

    let updatedAppSettings = JSON.parse(existingAppSettings.data) as AdminAppSettings;
    updatedAppSettings.FeatureManagement = { RfidReader: unitSetting.accountCardEnabled, PaymentTerminal: unitSetting.paymentTerminalEnabled, Printer: true };

    let updatedCustomSettings: AdminCustomSettings = { unitToken: tokenInput, ...unitSetting };
    await HALService.updateAppSettings(updatedAppSettings);
    await HALService.updateCustomSettings(updatedCustomSettings);
    await HALService.restartService();

    // Then, save in store
    dispatch(appActions.setSettings(updatedCustomSettings));
    navigate("/");
  }

  return (
    <div className="token-page">
      <div className="token-content">
        <h1>{t("token:TokenTitle")}</h1>
        <Input key="token" value={tokenInput} handleInputChange={(value) => setTokenInput(value)} handleKeyUp={(key) => key === "Enter" && handleTokenButtonClick()} />
        {isInvalidToken && <p className="token-error">{t("token:Error")}</p>}
        {hasError && <p className="token-error">{t("token:ConnectionError")}</p>}
        <Button attributes={{ disabled: isLoadingSettings || !tokenInput }} onClick={handleTokenButtonClick}>
          {t("token:Continue")}
        </Button>
      </div>
    </div>
  );
}
